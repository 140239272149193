import Button from 'src/components/ui/Button'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import { loginUrl } from '../../../../../store.config'
import PoliticaEntregaMiddle from './PoliticaEntregaMiddle'

import '../styles.scss'

const PoliticaEntrega = () => {
  return (
    <>
      <section className="w-full section-services delivery-policy_container">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar PoliticaEntrega-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- PoliticaEntrega Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-2xl italic font-bold text-center text-blue">
                    POLÍTICA DE ENTREGA
                  </h1>
                  <Button className="bg-blue justify-center text-white text-xs my-0 m-auto flex items-center h-[30px] max-w-[234px] w-full hover:bg-darkBlue transition-all rounded-sm">
                    <a
                      className="text-xs font-bold uppercase consult-button"
                      href={loginUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Rastrear um pedido
                    </a>
                  </Button>

                  <p className="mt-4 text-sm text-left">
                    Cada forma de entrega possui características e prazos
                    próprios. Todos os modelos são descritos detalhadamente
                    abaixo!
                  </p>
                  <h2 className="mt-4 text-xl italic font-bold text-left text-gray">
                    ECONÔMICA, NORMAL, RÁPIDA E AGENDADA
                  </h2>

                  <p className="mt-2 text-sm text-left">
                    O prazo de entrega começa a contar após a confirmação do seu
                    pagamento e há o acréscimo de um dia útil para aprovações de
                    pagamento ocorridas após às 14 horas. Os produtos são
                    enviados dentro do prazo previsto de entrega e o serviço da
                    transportadora utilizada pela Decathlon é devidamente
                    qualificado e fará as entregas com toda a segurança. Os
                    produtos de grande porte como equipamentos de Fitness são
                    entregues desmontados e em suas embalagens originais. Nossas
                    transportadoras não têm nenhuma qualificação e autorização
                    para realizar a montagem dos produtos ou qualquer outro
                    serviço que não seja apenas a entrega dos produtos
                    comprados.
                  </p>
                  <p className="mt-2 text-sm text-left">
                    Não fazemos entrega em Caixa Postal e pedidos endereçados
                    dessa forma serão devolvidos para nosso Centro de
                    Distribuição e será gerado um vale-troca no valor total do
                    pedido. Para seguir com o cancelamento, entre em contato com
                    a{' '}
                    <a
                      className="text-blue"
                      href="/atendimento"
                      target="_blank"
                    >
                      Central de Atendimento
                    </a>{' '}
                    .
                  </p>
                  <p className="mt-2 text-sm text-left">
                    Após a finalização do pedido não é possível alterar a forma
                    de entrega, solicitar adiantamento ou prioridade. Todos os
                    prazos de entrega levam em consideração o estoque, a região,
                    o processo de emissão da nota fiscal e o tempo de preparo do
                    produto.
                  </p>
                  <p className="mt-2 text-sm text-left">
                    As entregas dos produtos da Decathlon são feitas de segunda
                    a sexta-feira, exceto feriados nacionais e estaduais, dentro
                    do horário comercial das 8 às 18h. É necessário
                    disponibilizar uma pessoa responsável para aguardar a
                    entrega. Em casos excepcionais poderá ocorrer fora do
                    horário comercial.
                  </p>
                  <p className="mt-2 text-sm text-left">
                    Quando um pedido contém mais de um produto, em alguns casos,
                    por conta das dimensões e pesos da compra, podem ser
                    entregues separadamente.
                  </p>
                  <p className="mt-2 text-sm text-left">
                    Para compras já concluídas não é possível alterar o endereço
                    nem o serviço de entrega. Caso tenha informado o endereço
                    incorreto, entre em contato com a Central de Atendimento
                    para solicitar o cancelamento da compra. Se o pacote não for
                    entregue devido a um endereço errado, a transportadora o
                    devolverá para a Decathlon e será gerado um vale-troca no
                    valor total do pedido.
                  </p>
                </div>
                <div className="mt-4 hro">
                  <p className="my-4">
                    <strong className="my-4 text-left text-gray">
                      Rastreamento
                    </strong>
                  </p>
                  <p className="mt-4 text-sm text-left">
                    Para cada atualização no status do pedido é encaminhado
                    automaticamente um e-mail com a informação. O envio é feito
                    para o e-mail informado na hora da compra, não passível de
                    alteração. O prazo para o rastreio estar atualizado na
                    transportadora é de até 48 horas úteis após o envio.
                  </p>
                  <p className="mt-4 mb-2 text-sm text-left">
                    Para clientes Clube Decathlon dentro dos “Meus Pedidos”
                    ainda é possível ter acesso a todos os pedidos realizados,
                    verificar o status de cada um e acompanhar a entrega.
                  </p>
                  <p className="my-4">
                    <strong>Transporte</strong>
                  </p>
                  <p className="mt-4 text-sm">
                    As transportadoras que realizam as entregas da DECATHLON não
                    estão autorizadas a abrir embalagens ou realizar a entrega
                    utilizando janelas, telhados ou outros meios de difícil
                    acesso.
                  </p>
                  <p className="mt-2 text-sm">
                    Para realizar a entrega, o cliente tem que oferecer
                    facilidade de acesso. A Decathlon não assumirá a
                    responsabilidade por qualquer montagem, guincho para
                    apartamentos ou locais com difícil acesso. No caso de
                    existir alguma dificuldade que nos impeça de ter acesso
                    normal, o produto é deixado no local mais próximo que o
                    acesso permitir. A entrega pode ser feita a terceiros desde
                    que autorizada previamente pelo comprador. Ao receber o
                    produto, verifique as condições da embalagem. Caso esta
                    esteja avariada, deverá recusar o recebimento do produto,
                    anotando na Nota Fiscal a razão da recusa e informar o
                    ocorrido à{' '}
                    <a
                      className="text-blue"
                      href="/atendimento"
                      target="_blank"
                    >
                      Central de Atendimento
                    </a>
                    .
                  </p>
                  <p className="mt-2 text-sm">
                    Em caso de recebimento é necessário solicitar a troca ou
                    devolução, confira aqui e poderá solicitar a coleta em seu
                    endereço.
                  </p>
                  <p className="mt-2 text-sm">
                    Com exceção dos Correios, o pedido não pode ser retirado na
                    transportadora. Em casos de impossibilidade de recebimento,
                    o pedido irá retornar ao nosso Centro de Distribuição e será
                    gerado um vale-troca no valor total do pedido.
                  </p>
                  <p className="mt-2 text-sm">
                    Durante o transporte poderá ocorrer do pedido ser parado
                    para fiscalização. Retenção fiscal é um procedimento normal
                    realizado pela Secretaria da Fazenda para averiguação fiscal
                    dos produtos que entram na região. Como cada Estado possui
                    sua própria regulamentação e infelizmente não temos uma
                    estimativa de quando se encerra esta tramitação. Também não
                    há nenhuma ação que possa ser tomada para que este processo
                    se torne mais rápido ou para que a mercadoria seja liberada
                    de forma prioritária. Para seguir com o cancelamento entre
                    em contato com nossa Central de Atendimento.
                  </p>
                  <PoliticaEntregaMiddle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PoliticaEntrega
