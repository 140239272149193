const Locker = () => {
  return (
    <>
      <h2 className="text-gray italic text-left text-lg font-bold mt-4">
        RETIRADA EM LOCKER
      </h2>
      <p className="text-sm mt-2">
        A gestão dos pedidos para retirada em locker é feita pela empresa Clique
        e Retire e no momento fazemos a entrega deste modelo exclusivamente para
        os Estados de São Paulo e Rio de Janeiro.
      </p>
      <p className="text-sm mt-2">
        O prazo de entrega começa a contar após a confirmação do pagamento e há
        o acréscimo de um dia útil para aprovações de pagamento ocorridas após
        às 14 horas. Os produtos são enviados dentro do prazo previsto de
        entrega e o serviço da Clique e Retire é devidamente qualificado e fará
        as entregas com toda a segurança.. Após a finalização do pedido não é
        possível alterar a forma de entrega, solicitar adiantamento ou
        prioridade. Todos os prazos de entrega levam em consideração o estoque,
        a região, o processo de emissão da nota fiscal e o tempo de preparo do
        produto.
      </p>
      <p className="text-sm mt-2">
        Assim que o pedido chegar no locker escolhido, receberá a informação por
        SMS e por e-mail. Nesse informativo, encontrará o QR Code para abrir sua
        gaveta. Em até 3 dias, só comparecer ao local solicitado, aproximar o
        celular com o QR Code aberto no visor disponível que a porta que seu
        produto está será aberta automaticamente.
      </p>
      <p className="text-sm mt-2">
        Faremos envio de comunicações diariamente até que o pedido seja
        retirado. Caso não possa fazer a coleta, outra pessoa pode realizar
        desde que tenha acesso ao QR Code enviado. Se não coletado dentro do
        prazo descrito, receberá uma informação que seu pedido está retornando
        para nosso Centro de Distribuição e será gerado um vale troca no valor
        total da compra.
      </p>
      <p className="text-gray my-4">
        <strong>Produto indisponível após a compra</strong>
      </p>
      <p className="text-sm mt-2">
        Infelizmente, ocasionalmente, um produto pode ficar indisponível após
        finalizada a compra. Para esses casos, a Decathlon entra em contato
        diretamente com o cliente por e-mail informando da impossibilidade de
        envio deste item. Os demais serão entregues normalmente no prazo dado na
        hora da compra.
      </p>
      <p className="text-sm mt-2">
        Com isso, o cliente poderá optar pela restituição do valor pago na peça,
        realizar o cancelamento total da compra ou a substituição por outro
        produto com qualidade semelhante e de igual preço. Será necessário
        responder o email encaminhado ou entrar em contato com nossa{' '}
        <a className="text-blue" href="/atendimento" target="_blank">
          Central de Atendimento
        </a>
        .
      </p>
      <p className="text-gray my-4">
        <strong>Como retirar o produto no e-box/locker:</strong>
      </p>
      <div className="max-w-[560px]">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/ndB4dzw2qv4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <iframe
          className="mt-4"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/qK90Q3RqTXc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </>
  )
}

export default Locker
