import Seo from 'src/components/seo/Seo'
import PoliticaEntrega from 'src/components/servicos/ServicesPage/PoliticaEntrega'

function Page() {
  return (
    <>
      <Seo language="pt-br" title="Política de entrega | Decathlon" />
      <PoliticaEntrega />
    </>
  )
}

export default Page
