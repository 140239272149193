import Express from './Express'
import Locker from './Locker'

const PoliticaEntregaMiddle = () => {
  return (
    <>
      <div>
        <p className="my-4">
          <strong>Insucesso na entrega</strong>
        </p>
        <p className="text-sm mt-2">
          Para evitar que ocorra eventuais transtornos, a Decathlon compartilha
          com suas transportadoras parceiras o nome, endereço e telefone dos
          consumidores. Havendo dificuldade na localização do endereço, pode ser
          que receba um telefonema direto da transportadora solicitando
          informações complementares que facilitarão a conclusão da entrega.
        </p>
        <p className="text-sm mt-2">
          Permanecendo a adversidade, serão realizadas até 3 tentativas de
          entrega em dias diferentes com um prazo de até 48 horas úteis entre
          elas. Após a terceira tentativa sem sucesso, o pedido será devolvido e
          será gerado um vale-troca no valor total da compra (incluindo frete).
          Para seguir com o cancelamento do mesmo, é preciso entrar em contato
          com o Atendimento ao Cliente.
        </p>
        <p className="text-sm mt-2">
          Outra forma de comunicação do insucesso na entrega é o envio de e-mail
          para a confirmação de endereço e referências. Após recebermos o
          retorno, será realizada uma nova tentativa em até 72 horas úteis. Caso
          não tenhamos resposta em até 3 dias úteis, o pedido será devolvido e
          será gerado um vale-troca no valor total da compra (incluindo frete).
        </p>
        <p className="text-sm mt-2">
          Em alguns casos pode haver a alteração do prazo de entrega de forma
          involuntária tais como: dados do cadastro com informações incorretas,
          recusa no recebimento do pedido pela pessoa habilitada, condições
          climáticas desfavoráveis, greves locais e gerais, locais de destino
          com baixa população, difícil acesso ou distâncias das capitais. Se
          isto ocorrer, será feita a notificação por e-mail do incidente com o
          novo prazo para a entrega.
        </p>
        <p className="mt-2">
          <strong>Acareação da entrega</strong>
        </p>
        <p className="text-sm mt-2">
          Quando o consumidor não reconhece a entrega é necessário entrar em
          contato com nossa Central de Atendimento para solicitar a averiguação
          dos fatos.
        </p>
        <p className="text-sm mt-2">
          O nosso time irá solicitar uma acareação com a transportadora para
          verificar o comprovante de entrega no qual devem constar as
          assinaturas do entregador e do recebedor do produto. O prazo para o
          processo de acareação ser finalizado é de 96 horas úteis após a
          solicitação. O prazo para o consumidor solicitar a acareação é de 10
          dias úteis a partir da confirmação da entrega.
        </p>
        <p className="text-sm mt-2">
          Caso tenha recebido o pedido, mas esteja faltando um produto que
          consta na nota fiscal é necessário que entre em contato com nossa
          Central de Atendimento no prazo de até 96 horas úteis após o
          recebimento para que possamos averiguar.
        </p>
        <p className="my-4">
          <strong>Produto indisponível após a compra</strong>
        </p>
        <p className="text-sm mt-2">
          Infelizmente, ocasionalmente, um produto pode ficar indisponível após
          finalizada a compra. Para esses casos, a Decathlon entra em contato
          diretamente com o cliente por e-mail informando da impossibilidade de
          envio deste item. Os demais serão entregues normalmente no prazo dado
          na hora da compra.
        </p>
        <p className="text-sm mt-2">
          Com isso, o cliente poderá optar pela restituição do valor pago na
          peça, realizar o cancelamento total da compra ou a substituição por
          outro produto com qualidade semelhante e de igual preço. Será
          necessário responder o email encaminhado ou entrar em contato com
          nossa{' '}
          <a className="text-blue" href="/atendimento" target="_blank">
            Central de Atendimento
          </a>
          .
        </p>
        <Express />
        <Locker />
        <h2 className="text-gray italic text-left text-xl font-bold mt-4">
          MARKETPLACE E SITES PARCEIROS
        </h2>
        <p className="text-sm mt-2">
          Para os produtos adquiridos nos diversos marketplaces com os quais a
          Decathlon firmou contratos, a entrega irá ocorrer de acordo com o tipo
          de produto que é adquirido.
        </p>
        <p className="text-gray my-4">
          <strong>Produtos não volumosos:</strong>
        </p>
        <p className="text-sm mt-2">
          Todos os pedidos volumosos são entregues por transportadoras parceiras
          da Decathlon e é de nossa responsabilidade fornecer o rastreamento
          para o cliente. O link para consulta é disponibilizado no campo de
          mensagens de interação entre o vendedor com o comprador. Os pedidos
          são encaminhados para as transportadoras em até um dia útil após a
          confirmação de pagamento no sistema do parceiro marketplace Decathlon.
        </p>
        <p className="text-gray my-4">
          <strong>Produtos volumosos:</strong>
        </p>
        <p className="text-sm mt-2">
          Todas as entregas dos produtos não volumosos são de responsabilidade
          das transportadoras parceiras do marketplace com o qual a Decathlon
          firmou contrato e a disponibilização do rastreamento também é feita no
          início do pedido dentro do aplicativo ou diretamente no cadastro no
          site em qual a compra foi feita. A coleta do pedido é feita com o
          prazo de um dia útil após a confirmação de pagamento.
        </p>
        <p className="text-sm mt-2">
          Adquirindo produtos no site ou aplicativo Decathlon que não são
          vendidos e entregues pela Decathlon, o envio e comunicação de rastreio
          é de responsabilidade total do vendedor. Para cada atualização no
          status da compra é encaminhado um e-mail automático com a comunicação.
        </p>
      </div>
    </>
  )
}

export default PoliticaEntregaMiddle
