/* eslint-disable react/no-unescaped-entities */
const Express = () => {
  return (
    <>
      <h2 className="text-gray italic text-left text-lg font-bold mt-4">
        RETIRADA EM LOJA E EXPRESS
      </h2>
      <p className="text-sm mt-2">
        A retirada em loja é passível para todos os itens vendidos e entregues
        pela Decathlon, uma vez que o produto tenha estoque disponível na loja
        selecionada. Se não possuirmos a peça na unidade em que deseja, é
        possível fazer o envio direto de nosso Centro de Distribuição, que
        também necessita ter o estoque do produto. Consulte a viabilidade na
        página do produto ao informar seu CEP. O prazo de entrega nesta
        modalidade pode ser de forma express (em até 2hs) ou levar de 4 a 5 dias
        úteis após a confirmação de pagamento variando de acordo com a
        disponibilidade dos produtos em nossas unidades. A escolha da forma de
        entrega poderá ser feita diretamente no momento do fechamento do pedido.
      </p>

      <p className="text-sm mt-2">
        O prazo de entrega começa a contar após a confirmação do pagamento e há
        o acréscimo de um dia útil para aprovações de pagamento ocorridas após
        às 14 horas. Os produtos ficam disponíveis para retirada dentro do prazo
        previsto de entrega. Os produtos de grande porte como equipamentos de
        Fitness são entregues desmontados e em suas embalagens originais. Após a
        finalização do pedido não é possível alterar a forma de entrega,
        solicitar adiantamento ou prioridade. Todos os prazos de entrega levam
        em consideração o estoque, a região, o processo de emissão da nota
        fiscal e o tempo de preparo do produto.
      </p>
      <p className="text-sm mt-2">
        Somente após o recebimento do e-mail com a confirmação de que seu pedido
        está disponível para retirada na loja selecionada é que poderá ser feita
        a retirada. Assim que receber este e-mail, basta comparecer ao
        Atendimento ao Cliente da unidade com um documento oficial com foto e
        fazer a retirada. O prazo para coleta é de até 7 dias corridos, contando
        a partir da data da sinalização de que a compra chegou à loja. Se o
        pedido não for retirado no prazo, ele será integrado ao estoque da loja
        e será disponibilizado um crédito para utilizar novamente no site ou em
        qualquer uma de nossas unidades
      </p>
      <p className="text-sm mt-2">
        Não consegue fazer a retirada? Sem problemas, outra pessoa pode fazer no
        seu lugar! Para isso basta o terceiro comparecer ao atendimento ao
        cliente da loja com o número do pedido, o documento de identificação do
        comprador em forma física ou digital e um documento original próprio.
      </p>
      <p className="text-sm mt-2">
        Algumas unidades possuem estacionamento não gratuito, o valor do
        estacionamento não está incluso no frete grátis. Para confirmar o
        horário de funcionamento de todas nossas lojas basta acessar a aba
        "Nossas lojas" no topo do site.
      </p>
      <p className="mt-4 text-gray">
        <strong>Produto indisponível após a compra</strong>
      </p>
      <p className="text-sm mt-2">
        Infelizmente, ocasionalmente, um produto pode ficar indisponível após
        finalizada a compra. Para esses casos, a Decathlon entra em contato
        diretamente com o cliente por e-mail informando da impossibilidade de
        envio deste item. Os demais serão entregues normalmente no prazo dado na
        hora da compra.
      </p>
      <p className="text-sm mt-2">
        Com isso, o cliente poderá optar pela restituição do valor pago na peça,
        realizar o cancelamento total da compra ou a substituição por outro
        produto com qualidade semelhante e de igual preço. Será necessário
        responder o email encaminhado ou entrar em contato com nossa{' '}
        <a className="text-blue" href="/atendimento" target="_blank">
          Central de Atendimento
        </a>
        .
      </p>
    </>
  )
}

export default Express
